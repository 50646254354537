<p-confirmDialog #cd>
  <ng-template let-message pTemplate="headless">
    <div class="flex flex-column justify-content-center align-items-center p-4 dialog-width" >
      <div class="h-12 border-circle p-2 bg-red-100 flex justify-content-center align-items-center">
        <i class="fa-solid fa-triangle-exclamation" style="font-size: 2rem; color:rgb(230, 54, 54)"></i>
      </div>
      <p class="text-xl font-bold text-center mt-1">{{ message.header }}</p>
      <p class="text-base text-center mb-4">{{ message.message }}</p>
      <div class="flex flex-column w-full space-x-4 gap-2">
        <button (click)="cd.accept()" [label]="message.acceptLabel || 'Delete'"
                class="{{message.acceptButtonStyleClass || ''}} clm-primary-btn w-full"
                pButton
                type="button"></button>
        <button (click)="cd.reject()" [label]="message.rejectLabel || 'Cancel'" class="clm-secondary-btn w-full" pButton
                type="button"></button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
